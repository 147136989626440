import CategoricalScheme from '../../CategoricalScheme';
// @ts-ignore
import { bootstrapData } from '../../../../../../src/preamble';

const CustomColorConfig = () => {
  const color_schema = bootstrapData?.common?.conf?.COLOR_SCHEMA;
  const schemes = [
    {
      id: 'custom2',
      label: 'Custom Color',
      colors: color_schema,
    },
  ].map(s => new CategoricalScheme(s));

  return schemes;
};

export default CustomColorConfig;
